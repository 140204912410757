// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-for-aces-js": () => import("./../../../src/pages/forAces.js" /* webpackChunkName: "component---src-pages-for-aces-js" */),
  "component---src-pages-full-house-js": () => import("./../../../src/pages/fullHouse.js" /* webpackChunkName: "component---src-pages-full-house-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-odyssee-js": () => import("./../../../src/pages/odyssee.js" /* webpackChunkName: "component---src-pages-odyssee-js" */),
  "component---src-pages-plan-be-js": () => import("./../../../src/pages/planBE.js" /* webpackChunkName: "component---src-pages-plan-be-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-tabula-rasa-js": () => import("./../../../src/pages/tabulaRasa.js" /* webpackChunkName: "component---src-pages-tabula-rasa-js" */)
}

